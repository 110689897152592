.albumHome {
	border-radius: 20%;
	font-family: Trattatello,fantasy;
	width: 5vw;
	height: 5vw;
	box-shadow: 1px 1px 40px white;
	color: #1D0000;
	font-weight: bold;
	cursor: pointer;
}

.containerNewWorld {
	display: inline-block;
	font-family: Trattatello,fantasy;
	position: relative;
	background-color: #1D0000;
	width: 100%;
	/*height: auto;*/
}

.containerH {
	display: inline-block;
	font-family: Trattatello,fantasy;
	position: relative;
	background-color: #390202;
	width: 100%;
}

.containerAliens {
	display: inline-block;
	font-family: Trattatello,fantasy;
	position: relative;
	background-color: #492222;
	width: 100%;
}

.containerGift {
	display: inline-block;
	font-family: Trattatello,fantasy;
	position: relative;
	background-color: #845D5D;
	width: 100%;
}

.containerNewWorld button, .containerH button, .containerAliens button, .containerGift button {
	position: absolute;
	top: 51vw;
	left: 2vw;
	z-index: 2;
	background-color: white;
	border: none;
}

.containerNewWorld button:hover, .containerH button:hover, .containerAliens button:hover, .containerGift button:hover {
	box-shadow: 2px 2px 100px white;
}

.NewWorldAlbumCover, .HAlbumCover, .AliensAlbumCover, .GiftAlbumCover {
	width: 29%;
	height: auto;
	margin-top: 14vw;
	margin-bottom: 14vw;
	border-radius: 50%;
	box-shadow: 1px 1px 90px grey;
	opacity: 0.8;
	margin-left: 36vw;
	/*border: none;*/
}

.NewWorldAlbumCover:hover  {
	box-shadow: 1px 1px 180px 60px grey;
	cursor: pointer;
	opacity: 1;
}

.HAlbumCover:hover {
	box-shadow: 1px 1px 180px 50px white;
	cursor: pointer;
	opacity: 1;
}

.AliensAlbumCover:hover {
	box-shadow: 1px 1px 180px 50px #EACFCF;
	cursor: pointer;
	opacity: 1;
}

.GiftAlbumCover:hover {
	box-shadow: 1px 1px 180px 50px white;
	opacity: 1;
	cursor: pointer;
}

/* ------ New World Album -------- */

.NewWorldAlbumLyrics, .HAlbumLyrics, .AliensAlbumLyrics, .GiftAlbumLyrics {
	position: absolute;
	color: white;
	z-index: 1;
	writing-mode: vertical-rl;
}

.NewWorldAlbumLyrics1 {
	top: 4vw;
	left: 4vw;
}

.NewWorldAlbumLyrics2 {
	top: 22vw;
	left: 5vw;
}

.NewWorldAlbumLyrics3 {
	top: 40vw;
	left: 5vw;
}

.NewWorldAlbumLyrics4 {
	top: 44vw;
	left: 6vw;
}

.NewWorldAlbumLyrics5 {
	top: 12vw;
	left: 14vw;
}

.NewWorldAlbumLyrics6 {
	top: 26vw;
	left: 13vw;
}

.NewWorldAlbumLyrics7 {
	top: 31vw;
	left: 13vw;
}

.NewWorldAlbumLyrics8 {
	top: 7vw;
	left: 20vw;
}

.NewWorldAlbumLyrics9 {
	top: 16vw;
	left: 22vw;
}

.NewWorldAlbumLyrics10 {
	top: 29vw;
	left: 21vw;
}

.NewWorldAlbumLyrics11 {
	top: 43vw;
	left: 21.5vw;
}

.NewWorldAlbumLyrics12 {
	top: 2vw;
	left: 27vw;
}

.NewWorldAlbumLyrics13 {
	top: 8vw;
	left: 27vw;
}

.NewWorldAlbumLyrics14 {
	top: 14vw;
	left: 27vw;
}

.NewWorldAlbumLyrics15 {
	top: 20vw;
	left: 27vw;
}

.NewWorldAlbumLyrics16 {
	top: 32vw;
	left: 27vw;
}

.NewWorldAlbumLyrics17 {
	top: 40vw;
	left: 31vw;
}

.NewWorldAlbumLyrics18 {
	top: 4vw;
	left: 64vw;
}

.NewWorldAlbumLyrics19 {
	top: 8vw;
	left: 64vw;
}

.NewWorldAlbumLyrics20 {
	top: 14vw;
	left: 64vw;
}

.NewWorldAlbumLyrics21 {
	top: 26vw;
	left: 64vw;
}

.NewWorldAlbumLyrics22 {
	top: 37vw;
	left: 64vw;
}

.NewWorldAlbumLyrics23 {
	top: 11vw;
	left: 74vw;
}

.NewWorldAlbumLyrics24 {
	top: 23vw;
	left: 74vw;
}

.NewWorldAlbumLyrics25 {
	top: 42vw;
	left: 74vw;
}

.NewWorldAlbumLyrics26 {
	top: 1vw;
	left: 80vw;
}

.NewWorldAlbumLyrics27 {
	top: 6vw;
	left: 79vw;
}

.NewWorldAlbumLyrics28 {
	top: 18vw;
	left: 82vw;
}

.NewWorldAlbumLyrics29 {
	top: 26vw;
	left: 82vw;
}

.NewWorldAlbumLyrics30 {
	top: 37vw;
	left: 78vw;
}

.NewWorldAlbumLyrics31 {
	top: 3vw;
	left: 90vw;
}

.NewWorldAlbumLyrics32 {
	top: 12vw;
	left: 91vw;
}

.NewWorldAlbumLyrics33 {
	top: 22vw;
	left: 86vw;
}

.NewWorldAlbumLyrics34 {
	top: 43vw;
	left: 86vw;
}

.NewWorldAlbumLyrics35 {
	top: 49vw;
	left: 89vw;
}

.NewWorldAlbumLyrics36 {
	top: 49vw;
	left: 89vw;
}

.NewWorldAlbumLyrics37 {
	top: 43vw;
	left: 45vw;
}

/* ---------- H Album --------- */

.HAlbumLyrics1 {
	top: 7vw;
	left: 2vw;
}

.HAlbumLyrics2 {
	top: 19vw;
	left: 2vw;
}

.HAlbumLyrics3 {
	top: 29vw;
	left: 4vw;
}

.HAlbumLyrics4 {
	top: 47vw;
	left: 7vw;
}

.HAlbumLyrics5 {
	top: 16vw;
	left: 10vw;
}

.HAlbumLyrics6 {
	top: 24vw;
	left: 12vw;
}

.HAlbumLyrics7 {
	top: 32vw;
	left: 11vw;
}

.HAlbumLyrics8 {
	top: 2vw;
	left: 18vw;
}

.HAlbumLyrics9 {
	top: 10vw;
	left: 19vw;
}

.HAlbumLyrics10 {
	top: 30vw;
	left: 21vw;
}

.HAlbumLyrics11 {
	top: 35vw;
	left: 21vw;
}

.HAlbumLyrics12 {
	top: 4vw;
	left: 27vw;
}

.HAlbumLyrics13 {
	top: 19vw;
	left: 30vw;
}

.HAlbumLyrics14 {
	top: 42vw;
	left: 32vw;
}

.HAlbumLyrics15 {
	top: 9vw;
	left: 66vw;
}

.HAlbumLyrics16 {
	top: 33vw;
	left: 63vw;
}

.HAlbumLyrics17 {
	top: 47vw;
	left: 65vw;
}

.HAlbumLyrics18 {
	top: 1vw;
	left: 72vw;
}

.HAlbumLyrics19 {
	top: 6vw;
	left: 74vw;
}

.HAlbumLyrics20 {
	top: 19vw;
	left: 74vw;
}

.HAlbumLyrics21 {
	top: 26vw;
	left: 74vw;
}

.HAlbumLyrics22 {
	top: 46vw;
	left: 74vw;
}

.HAlbumLyrics23 {
	top: 11vw;
	left: 83vw;
}

.HAlbumLyrics24 {
	top: 18vw;
	left: 80vw;
}

.HAlbumLyrics25 {
	top: 39vw;
	left: 83vw;
}

.HAlbumLyrics26 {
	top: 49vw;
	left: 86vw;
}

.HAlbumLyrics27 {
	top: 3vw;
	left: 90vw;
}

.HAlbumLyrics28 {
	top: 21vw;
	left: 90vw;
}

.HAlbumLyrics29 {
	top: 30vw;
	left: 90vw;
}

.HAlbumLyrics30 {
	top: 35vw;
	left: 93vw;
}

.HAlbumLyrics31 {
	top: 45vw;
	left: 87.7vw;
}

/* --------- Aliens Album -------- */

.AliensAlbumLyrics1 {
	top: 7vw;
	left: 3vw;
}

.AliensAlbumLyrics2 {
	top: 14vw;
	left: 3vw;
}

.AliensAlbumLyrics3 {
	top: 26vw;
	left: 3vw;
}

.AliensAlbumLyrics4 {
	top: 37vw;
	left: 1vw;
}

.AliensAlbumLyrics5 {
	top: 39vw;
	left: 7vw;
}

.AliensAlbumLyrics6 {
	top: 4vw;
	left: 11vw;
}

.AliensAlbumLyrics7 {
	top: 16vw;
	left: 14vw;
}

.AliensAlbumLyrics8 {
	top: 28vw;
	left: 12vw;
}

.AliensAlbumLyrics9 {
	top: 33vw;
	left: 12vw;
}

.AliensAlbumLyrics10 {
	top: 10vw;
	left: 20vw;
}

.AliensAlbumLyrics11 {
	top: 19vw;
	left: 18vw;
}

.AliensAlbumLyrics12 {
	top: 30vw;
	left: 21vw;
}

.AliensAlbumLyrics13 {
	top: 40vw;
	left: 22vw;
}

.AliensAlbumLyrics14 {
	top: 50vw;
	left: 18vw;
}

.AliensAlbumLyrics15 {
	top: 1vw;
	left: 27vw;
}

.AliensAlbumLyrics16 {
	top: 21vw;
	left: 26vw;
}

.AliensAlbumLyrics17 {
	top: 36vw;
	left: 30vw;
}

.AliensAlbumLyrics18 {
	top: 12vw;
	left: 65vw;
}

.AliensAlbumLyrics19 {
	top: 37vw;
	left: 67vw;
}

.AliensAlbumLyrics20 {
	top: 49vw;
	left: 67vw;
}

.AliensAlbumLyrics21 {
	top: 2vw;
	left: 71vw;
}

.AliensAlbumLyrics22 {
	top: 8vw;
	left: 73vw;
}

.AliensAlbumLyrics23 {
	top: 27vw;
	left: 73vw;
}

.AliensAlbumLyrics24 {
	top: 34vw;
	left: 76vw;
}

.AliensAlbumLyrics25 {
	top: 6vw;
	left: 85vw;
}

.AliensAlbumLyrics26 {
	top: 16vw;
	left: 85vw;
}

.AliensAlbumLyrics27 {
	top: 33vw;
	left: 86vw;
}

.AliensAlbumLyrics28 {
	top: 37vw;
	left: 83vw;
}

.AliensAlbumLyrics29 {
	top: 4vw;
	left: 90vw;
}

.AliensAlbumLyrics30 {
	top: 12vw;
	left: 90vw;
}

.AliensAlbumLyrics31 {
	top: 16vw;
	left: 91vw;
}

.AliensAlbumLyrics32 {
	top: 19vw;
	left: 92vw;
}

.AliensAlbumLyrics33 {
	top: 34vw;
	left: 90vw;
}

.AliensAlbumLyrics34 {
	top: 39vw;
	left: 94vw;
}

.AliensAlbumLyrics35 {
	top: 45vw;
	left: 89vw;
}

/* ---------- Gift Album ---------- */

.GiftAlbumLyrics1 {
	top: 6vw;
	left: 2vw;
}

.GiftAlbumLyrics2 {
	top: 12vw;
	left: 2vw;
}

.GiftAlbumLyrics3 {
	top: 18vw;
	left: 2vw;
}

.GiftAlbumLyrics4 {
	top: 23vw;
	left: 3vw;
}

.GiftAlbumLyrics5 {
	top: 36vw;
	left: 3vw;
}

.GiftAlbumLyrics6 {
	top: 42vw;
	left: 4vw;
}

.GiftAlbumLyrics7 {
	top: 8vw;
	left: 9vw;
}

.GiftAlbumLyrics8 {
	top: 16vw;
	left: 9vw;
}

.GiftAlbumLyrics9 {
	top: 30vw;
	left: 10vw;
}

.GiftAlbumLyrics10 {
	top: 35vw;
	left: 10vw;
}

.GiftAlbumLyrics11 {
	top: 2vw;
	left: 19vw;
}

.GiftAlbumLyrics12 {
	top: 20vw;
	left: 19vw;
}

.GiftAlbumLyrics13 {
	top: 26vw;
	left: 20vw;
}

.GiftAlbumLyrics14 {
	top: 42vw;
	left: 16vw;
}

.GiftAlbumLyrics15 {
	top: 49vw;
	left: 20vw;
}

.GiftAlbumLyrics16 {
	top: 4vw;
	left: 28vw;
}

.GiftAlbumLyrics17 {
	top: 12vw;
	left: 27vw;
}

.GiftAlbumLyrics18 {
	top: 24vw;
	left: 26vw;
}

.GiftAlbumLyrics19 {
	top: 46vw;
	left: 26vw;
}

.GiftAlbumLyrics20 {
	top: 50vw;
	left: 26vw;
}

.GiftAlbumLyrics21 {
	top: 3vw;
	left: 65vw;
}

.GiftAlbumLyrics22 {
	top: 19vw;
	left: 64vw;
}

.GiftAlbumLyrics23 {
	top: 36vw;
	left: 66vw;
}

.GiftAlbumLyrics24 {
	top: 12vw;
	left: 74vw;
}

.GiftAlbumLyrics25 {
	top: 24vw;
	left: 74vw;
}

.GiftAlbumLyrics26 {
	top: 33vw;
	left: 72vw;
}

.GiftAlbumLyrics27 {
	top: 39vw;
	left: 71vw;
}

.GiftAlbumLyrics28 {
	top: 6vw;
	left: 81vw;
}

.GiftAlbumLyrics29 {
	top: 17vw;
	left: 81vw;
}

.GiftAlbumLyrics30 {
	top: 29vw;
	left: 80vw;
}

.GiftAlbumLyrics31 {
	top: 40vw;
	left: 79vw;
}

.GiftAlbumLyrics32 {
	top: 50vw;
	left: 82vw;
}

.GiftAlbumLyrics33 {
	top: 7vw;
	left: 88vw;
}

.GiftAlbumLyrics34 {
	top: 18vw;
	left: 88vw;
}

.GiftAlbumLyrics35 {
	top: 24vw;
	left: 93vw;
}

.GiftAlbumLyrics36 {
	top: 36vw;
	left: 89vw;
}