/*@media screen and (max-height: 844px) {
	.welcome {
		color: red;
	}
}

@media (max-height: 844px) {
	.album {
		height: 100vh;
	}
}*/


/*@keyframes house {
	from {
		box-shadow: 1px 1px 0px grey;
	}
	to {
		box-shadow: 1px 1px 100px grey;
	}
}*/

html {
	background-color: #1D0000;
}

body {
	background-color: #1D0000;
	/*background-image: url('./Home/world.JPG');
	background-position: 40% 50%;
	background-size: cover;
	background-repeat: no-repeat;*/
	height: 100vh;
}

.container {
	background-image: url('./Home/world.JPG'); /* Image from 华晨宇工作室微博 */
	background-position: 40% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	/*opacity: 0.9;*/
}

.containerAbout {
	background-image: url('./About/Light.JPG'); /* Image from 华晨宇工作室微博 */
	background-position: 55% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.container520 {
	height: 100%;
	overflow: hidden;
}

#root {
	height: 100%;
	background-color: #1D0000;
}

div p a {
	color: white;
}

.message {
	color: white;
	font-style: italic;
	position: absolute;
	font-size: 1.4vh;
	font-weight: bold;
	z-index: 1;
	right: 2vw;
	top: 90vh;
}

.about {
	font-style: normal;
	top: 96vh;
	right: 4vw;
}

.aboutMessage {
	text-align: center;
}

.aboutMessage, .aboutDetails {
	color: white;
	position: absolute;
	z-index: 1;
	font-size: 3vh;
	font-weight: bold;
	/*text-align: center;*/
	text-shadow: 2px 2px 26px white;
}

.fontSwitch {
	font-family: Trattatello,fantasy;
}

.noteSpace {
	bottom: 0vh;
	left: 1.5vw;
	font-size: 1.5vh;
	font-family: "Lucida Console", Courier, monospace;
	color: white;
}

.messageMargins {
	top: 10vh;
	right: 5vw;
}

.detailsMargins {
	bottom: 0vh;
	left: 2vw;
	font-size: 1.5vh;
}

.containerAbout, .containerQuotes,
.SongContainer {
	display: inline-block;
	font-family: Trattatello,fantasy;
	position: relative;
}

.containerAbout button, .containerQuotes button {
	position: absolute;
	top: 4vh;
	left: 2vw;
	z-index: 2;
}

.containerRedirect button, .container520 button {
	position: absolute;
	top: 47vw;
	left: 5vw;
	z-index: 2;
	width: 8vw;
	height: 8vw;
	/*font-size: 1.5vw;*/
}

.container button[id="albumNewWorld"] {
	position: absolute;
	top: 32vh;
	right: 2vw;
	width: 12.5vw;
	height: 6vw;
	font-size: 1.5vw;
	border-radius: 20%;
	z-index: 1;
	font-weight: bold;
	font-style: italic;
}

.container button[id="albumH"] {
	position: absolute;
	top: 43vh;
	right: 2vw;
	width: 12.5vw;
	height: 6vw;
	font-size: 1.5vw;
	border-radius: 20%;
	z-index: 1;
	font-weight: bold;
	font-style: italic;
}

.container button[id="albumAliens"] {
	position: absolute;
	top: 54vh;
	right: 2vw;
	width: 12.5vw;
	height: 6vw;
	font-size: 1.5vw;
	border-radius: 20%;
	z-index: 1;
	font-weight: bold;
	font-style: italic;
}

.container button[id="albumGift"] {
	position: absolute;
	top: 65vh;
	right: 2vw;
	width: 12.5vw;
	height: 6vw;
	font-size: 1.5vw;
	border-radius: 20%;
	z-index: 1;
	font-weight: bold;
	font-style: italic;
}

.containerRedirect button:hover, .container520 button:hover {
	box-shadow: 2px 2px 100px white;
}

.containerRedirect, .container520 {
	display: inline-flex;
	font-family: Trattatello,fantasy;
	position: relative;
}

.backButton {
	border-radius: 50%;
	font-family: Trattatello,fantasy;
	width: 10vh;
	height: 10vh;
	font-size: 3vh;
	box-shadow: 1px 1px 40px white;
	color: #1D0000;
	font-weight: bold;
	cursor: pointer;
	background-color: white;
	border: none;
	/*text-shadow: 2px 2px 15px #1D0000;*/
}

.backButton:hover {
	box-shadow: 2px 2px 60px 10px white;
}

.container img {
	width: 100%;
	height: auto;
}

.container, .containerAbout {
	width: 100vw;
	height: 100vh;
	position: relative;
	font-family: Trattatello,fantasy;
	
}

.fontClass {
	font-family: Trattatello,fantasy;
	font-size: 6vw;
}

.welcome {
	position: absolute;
	top: 70vh;
	right: 10vw;
	color: white;
	font-size: 8vh;
}

.home {
	width: 100%;
	height: 100%;
	opacity: 0.9;
	box-shadow: 1px 1px 90px #220303;
}

.gotoButton {
	width: 10vw;
	height: 4vw;
	font-size: 1.5vw;
	position: absolute;
	z-index: 4;
	top: 1.5vw;
	right: 3vw;
	border-radius: 20%;
	font-weight: bold
}

.buttonFont {
	font-family: "Lucida Console", Courier, monospace;
	font-size: 0.9vw;
	font-weight: bold;
	right: 12vw;
}

.button3 {
	right: 2vw;
}

.ToLove {
	width: 14vw;
	right: 15vw;
	border-radius: 20%;
}

.back520 {
	background-color: #000000;
	width: 100%;
}

/*.hcy520 {
	width: 2vw;
	height: 2vw;
}*/

.music {
	position: absolute;
	top: 6vh;
	right: 10vw;
	color: white;
	font-style: italic;
	font-size: 6vw;
}

/*.welcome::after {
	content: "|";
	position: absolute;
	animation-name: typing;
	animation-duration: 0.5s;
	animation-timing-function: linear;
	animation-delay: 0s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}*/

/* New World Album*/

.album {
	width: 100vw;
	height: 100vh;
	text-align: center;
	display: inline-flex;
}

.albumNewWorld {
	background-color: #1D0000;
}

/*.NewWorldAlbumCover,
.HAlbumCover, .AliensAlbumCover, .GiftAlbumCover {
	width: 100%;
	height: auto;
	margin-top: 25vh;
	border-radius: 50%;
	box-shadow: 1px 1px 90px grey;
	z-index: 4;
	opacity: 0.8;
}*/

.hcy520 {
	width: 66vh;
	height: auto;
	margin-top: 30vh;
	border-radius: 50%;
	box-shadow: 1px 1px 90px grey;
	opacity: 0.8;
	margin-left: 37vw;
}

.hcy520:hover {
	box-shadow: 1px 1px 180px 50px grey;
	cursor: pointer;
	opacity: 1;
}

/*.NewWorldAlbumCover {
	position: absolute;
}*/

/*.NewWorldAlbumCover:hover {
	box-shadow: 1px 1px 180px 50px grey;
	cursor: pointer;
	opacity: 1;
	display: none;
}*/

/*.NewWorldAlbumCover:hover p[id="continue"] {
	visibility: visible;
}*/

/* ------ switch on hover
.intoNewWorld{
	position: relative;
}

.NewWorldHereCover {
	left: 0;
	width: 100%;
	height: auto;
	margin-top: 25vh;
	border-radius: 50%;
	box-shadow: 1px 1px 90px grey;
	z-index: 0;
}

.NewWorldHereCover:hover {
	box-shadow: 1px 1px 180px 50px grey;
	cursor: pointer;
}*/


.NewWorldAlbum {
	opacity: 0.6;
}

/*.albumMessage {
	color: white;
	position: absolute;
	font-size: 1vw;
	font-weight: bold;
	text-align: center;
	text-shadow: 2px 2px 26px white;
	text-align: center;
	left: 45vw;
}*/

.albumShadow {
	box-shadow: 1px 1px 90px grey;
	border-radius: 20%;
}

.SongContainer button {
	position: absolute;
	top: 50vw;
	left: 1vw;
	width: 6vw;
	height: 6vw;
}

.songBullfight {
	position: absolute;
	left: 2vw;
	top: 5vw;
	width: 15%;
	height: auto;
	box-shadow: 1px 1px 90px #5B4E4E;
	border-radius: 20%;
	
}

.songBullfight:hover {
	box-shadow: 1px 1px 180px 30px #5B4E4E;
	width: 15.5%;
	cursor: pointer;
}

.songLove {
	position: absolute;
	left: 2vw;
	top: 30vw;
	width: 15%;
	height: auto;
	box-shadow: 1px 1px 90px #4F4A4A;
	border-radius: 20%;
}

.songLove:hover {
	box-shadow: 1px 1px 180px 30px #4F4A4A;
	width: 15.5%;
	cursor: pointer;
}

.songHouse {
	position: absolute;
	left: 18vw;
	top: 15vw;
	width: 15%;
	height: auto;
	box-shadow: 1px 1px 90px #804D4D;
	border-radius: 20%;
}

.songHouse:hover {
	box-shadow: 1px 1px 180px 30px #804D4D;
	width: 15.5%;
	cursor: pointer;
}

.songET {
	position: absolute;
	left: 18vw;
	top: 39vw;
	width: 15%;
	height: auto;
	box-shadow: 1px 1px 90px #DB9C9C;
	border-radius: 20%;
}

.songET:hover {
	box-shadow: 1px 1px 180px 30px #DB9C9C;
	width: 15.5%;
	cursor: pointer;
}

.songSplit {
	position: absolute;
	right: 18vw;
	top: 15vw;
	width: 15%;
	height: auto;
	box-shadow: 1px 1px 90px #6DAC9D;
	border-radius: 20%;
}

.songSplit:hover {
	box-shadow: 1px 1px 180px 30px #6DAC9D;
	width: 15.5%;
	cursor: pointer;
}

.songTree {
	position: absolute;
	right: 18vw;
	top: 39vw;
	width: 15%;
	height: auto;
	box-shadow: 1px 1px 90px #6D86AC;
	border-radius: 20%;
}

.songTree:hover {
	box-shadow: 1px 1px 180px 30px #6D86AC;
	width: 15.5%;
	cursor: pointer;
}

.songArrival {
	position: absolute;
	right: 2vw;
	top: 30vw;
	width: 15%;
	height: auto;
	box-shadow: 1px 1px 90px #FFFFFF;
	border-radius: 9%;
}

.songArrival:hover {
	box-shadow: 1px 1px 180px 30px #FFFFFF;
	width: 15.5%;
	cursor: pointer;
}

.songNewWorld {
	position: absolute;
	right: 2vw;
	top: 5vw;
	width: 15%;
	height: auto;
	box-shadow: 1px 1px 90px #545353;
	border-radius: 20%;
}

.songNewWorld:hover {
	box-shadow: 1px 1px 180px 30px #545353;
	width: 15.5%;
	cursor: pointer;
}


/*.effect {
	animation: house 0.4s infinite
}*/

/* H Album */

.HAlbumCover {
	width: 100%;
	box-shadow: 1px 1px 90px white;
}

.HAlbumCover:hover {
	box-shadow: 1px 1px 180px 10px white;
	opacity: 1;
	cursor: pointer;
}

.albumH {
	background-color: #390202;
}

/* Aliens Album */

.albumAliens {
	background-color: #492222;
}

.AliensAlbumCover {
	width: 107%;
	box-shadow: 1px 1px 90px #EACFCF;
}

.AliensAlbumCover:hover {
	box-shadow: 1px 1px 180px 10px #EACFCF;
	opacity: 1;
	cursor: pointer;
}

/* Gift Album */ 

.albumGift {
	background-color: #845D5D;
}

.GiftAlbumCover {
	width: 107%;
	box-shadow: 1px 1px 90px white;
}

.GiftAlbumCover:hover {
	box-shadow: 1px 1px 180px 10px white;
	opacity: 1;
	cursor: pointer;
}

/* ---------- Settings ------------ */

.fontShadow {
	text-shadow: 2px 2px 26px white;
}

.fontShadowAliens {
	text-shadow: 2px 2px 26px #EACFCF;
}

.font {
	font-size: 1.5vw;
}

.font1 {
	font-size: 1.8vw;
}

.font2 {
	font-size: 2vw;
}

.font3 {
	font-size: 2.2vw;
}

/* Letter font*/

.font4 {
	font-family: "Lucida Console", Courier, monospace;
	writing-mode: vertical-rl;
	color: white;
}

.horizontalWriting {
	writing-mode: horizontal-tb !important;
}

.font5 {
	font-size: 3vw;
}

.font6 {
	font-size: 1vw;
}

.font7 {
	font-size: 1.3vw;
}

.font8 {
	font-size: 3.3vw;
}

.font9 {
	font-family: "Lucida Console", Courier, monospace;
	color: white;
	font-size: 3vh;
	right: 5vw;
	top: 15vh;
}

.details {
	color: white;
	font-family: Trattatello,fantasy;
	writing-mode: vertical-rl;
	width: auto;
}

.marginTop1 {
	margin-top: 5.5vw;
}

.marginTop2 {
	margin-top: 16vw;
}

/*.marginTop3 {
	margin-top: 55vh;
}*/

.marginTop4 {
	margin-top: 12vw;
}

/*.marginTop5 {
	margin-top: 50vh;
}*/

.marginTop6 {
	margin-top: 1.5vw;
}

.marginTop7 {
	margin-top: 4.5vw;
}

.marginLeft1 {
	margin-left: 2vw;
}

/* --------------- Quotes page ---------------- */

.horizontalMargin {
	top: 1vw;
	left: 1vw;
	text-align: center;
}

.horizontalMargin1 {
	top: 1vw;
	left: 25vw;
	text-align: center;
}

.horizontalMargin2 {
	top: 1vw;
	left: 35vw;
	text-align: center;
}

.horizontalMargin3 {
	top: 1vw;
	left: 50vw;
	text-align: center;
}

.horizontalMargin4 {
	top: 1vw;
	left: 70vw;
	text-align: center;
}

.horizontalMargin5 {
	top: 1vw;
	left: 81vw;
	right: 2vw;
	text-align: center;
}

.horizontalMargin6 {
	left: 10vw;
}

.horizontalMargin7 {
	bottom: 1.5vw;
	right: 2.5vw;
	font-size: 1.5vh;
}

.redirectMessage, .messageLove {
	writing-mode: vertical-rl;
	position: absolute;
	color: white;
}

.redirectMessage1 {
	top: 5vw;
	left: 2vw;
}

.redirectMessage2 {
	top: 8vw;
	left: 6vw;
}

.redirectMessage3 {
	top: 8vw;
	left: 8vw;
}

.redirectMessage4 {
	top: 5vw;
	left: 11vw;
}

.redirectMessage5 {
	top: 12vw;
	left: 10vw;
}

.redirectMessage6 {
	top: 9vw;
	left: 12vw;
}

.redirectMessage7 {
	top: 13vw;
	left: 20vw;
	font-style: italic;
}

.redirectNote {
	top: 38vw;
	left: 2vw;
	color: white;
	position: absolute;
	text-align: center;
}

/* ------ Love ------- */

.messageLove1 {
	top: 8vw;
	left: 5vw;
}

.messageLove2 {
	top: 11vw;
	left: 7.5vw;
}

.messageLove3 {
	top: 12vw;
	left: 8vw;
}

.messageLove4 {
	top: 10vw;
	left: 11.5vw;
}

.messageLove5 {
	top: 12vw;
	left: 15vw;
}

.messageLove6 {
	top: 17vw;
	left: 16vw;
	writing-mode: horizontal-tb;
}

.messageLove7 {
	top: 23vw;
	left: 22vw;
}

.messageLove8 {
	top: 23vw;
	left: 28vw;
	writing-mode: horizontal-tb;
}

.messageLove9 {
	top: 27vw;
	left: 26vw;
}

.messageLove10 {
	top: 40vw;
	left: 22vw;
	writing-mode: horizontal-tb;
}

.messageLove11 {
	top: 43vw;
	left: 24vw;
	writing-mode: horizontal-tb;
}

/* ------- song details in their own css files------ */