.songDetails, .loveDetails, .houseDetails, .etDetails, .splitDetails, .treeDetails, .arrivalDetails, .newWorldDetails {
	position: absolute;
	color: white;
	z-index: 1;
	writing-mode: vertical-rl;
}

.songDetails1 {
	top: 5vw;
	left: 5vw;
}

.songDetails2 {
	top: 8vw;
	left: 9vw;
}

.songDetails3 {
	top: 9vw;
	left: 9vw;
}

.songDetails4 {
	top: 26vw;
	left: 18vw;
}

.songDetails5 {
	top: 24vw;
	left: 20vw;
}

.songDetails6 {
	top: 36vw;
	left: 20vw;
}

.songDetails7 {
	top: 9vw;
	left: 26vw;
}

.songDetails8 {
	top: 12vw;
	left: 30vw;
}

.songDetails9 {
	top: 10vw;
	left: 33vw;
}

.songDetails10 {
	top: 8vw;
	left: 32vw;
}

.songDetails11 {
	top: 26vw;
	right: 1vw;
	font-style: italic;
}

/* --------- SongLove --------- */

.loveDetails1 {
	top: 30vw;
	left: 10vw;
}

.loveDetails2 {
	top: 29vw;
	left: 13vw;
}

.loveDetails3 {
	top: 32vw;
	left: 14vw;
}

.loveDetails4 {
	top: 10vw;
	left: 20vw;
}

.loveDetails5 {
	top: 9vw;
	left: 24vw;
}

.loveDetails6 {
	top: 15vw;
	left: 33vw;
}

.loveDetails7 {
	top: 11vw;
	left: 32vw;
}

.loveDetails8 {
	top: 18.5vw;
	left: 36vw;
}

.loveDetails9 {
	top: 15vw;
	left: 38vw;
}

.loveDetails10 {
	top: 17vw;
	left: 39vw;
}

.loveDetails11 {
	top: 4vw;
	left: 70vw;
}

.loveDetails12 {
	top: 6vw;
	left: 70vw;
}

.loveDetails13 {
	top: 10vw;
	left: 77vw;
}

.loveDetails14 {
	top: 9vw;
	left: 78vw;
}

.loveDetails15 {
	top: 23vw;
	right: 0.5vw;
	font-style: italic;
}

/* ---------- SongHouse -------- */

.houseDetails1 {
	top: 8vw;
	left: 8vw;
}

.houseDetails2 {
	top: 6vw;
	left: 11vw;
}

.houseDetails3 {
	top: 8vw;
	left: 14vw;
}

.houseDetails4 {
	top: 12vw;
	left: 11vw;
}

.houseDetails5 {
	top: 8vw;
	left: 50vw;
}

.houseDetails6 {
	top: 15vw;
	left: 53vw;
}

.houseDetails7 {
	top: 18vw;
	left: 52vw;
}

.houseDetails8 {
	top: 20vw;
	left: 56vw;
}

.houseDetails9 {
	top: 36vw;
	left: 66vw;
}

.houseDetails10 {
	top: 36vw;
	left: 65vw;
}

.houseDetails11 {
	top: 39vw;
	left: 70vw;
}

.houseDetails12 {
	top: 41.5vw;
	left: 69vw;
}

.houseDetails13 {
	top: 40vw;
	left: 73vw;
}

.houseDetails14 {
	top: 32vw;
	left: 75vw;
}

.houseDetails15 {
	top: 34vw;
	left: 75vw;
}

.houseDetails16 {
	top: 2vw;
	left: 85vw;
}

.houseDetails17 {
	top: 6vw;
	left: 86vw;
}

.houseDetails18 {
	top: 4vw;
	left: 86vw;
}

.houseDetails19 {
	top: 47vw;
	right: 0.5vw;
	font-style: italic;
}

/* -------- SongET ---------- */

.etDetails1 {
	top: 5vw;
	left: 2vw;
}

.etDetails2 {
	top: 14vw;
	left: 5vw;
}

.etDetails3 {
	top: 10vw;
	left: 5vw;
}

.etDetails4 {
	top: 8vw;
	left: 9vw;
}

.etDetails5 {
	top: 13vw;
	left: 10vw;
}

.etDetails6 {
	top: 18vw;
	left: 13vw;
}

.etDetails7 {
	top: 2vw;
	left: 44vw;
}

.etDetails8 {
	top: 2vw;
	left: 46.5vw;
}

.etDetails9 {
	top: 4vw;
	left: 47vw;
}

.etDetails10 {
	top: 13vw;
	left: 47vw;
}

.etDetails11 {
	top: 30vw;
	left: 51vw;
}

.etDetails12 {
	top: 26vw;
	left: 52vw;
}

.etDetails13 {
	top: 30vw;
	left: 54vw;
}

.etDetails14 {
	top: 15vw;
	left: 70vw;
}

.etDetails15 {
	top: 20vw;
	left: 70vw;
}

.etDetails16 {
	top: 18vw;
	left: 80vw;
}

.etDetails17 {
	top: 16vw;
	left: 79vw;
}

.etDetails18 {
	top: 30vw;
	right: 0.5vw;
	font-style: italic;
}

/* ---------- SongSplit ---------- */

.splitDetails1 {
	top: 20vw;
	left: 4vw;
}

.splitDetails2 {
	top: 20vw;
	left: 8vw;
}

.splitDetails3 {
	top: 18vw;
	left: 10vw;
}

.splitDetails4 {
	top: 26vw;
	left: 12vw;
}

.splitDetails5 {
	top: 30vw;
	left: 20vw;
}

.splitDetails6 {
	top: 30vw;
	left: 24vw;
}

.splitDetails7 {
	top: 26vw;
	left: 30vw;
}

.splitDetails8 {
	top: 32vw;
	left: 30vw;
}

.splitDetails9 {
	top: 31vw;
	left: 36vw;
}

.splitDetails10 {
	top: 2vw;
	left: 40vw;
}

.splitDetails11 {
	top: 2vw;
	left: 41vw;
}

.splitDetails12 {
	top: 6vw;
	left: 45vw;
}

.splitDetails13 {
	top: 7vw;
	left: 46vw;
}

.splitDetails14 {
	top: 6vw;
	left: 49vw;
}

.splitDetails15 {
	top: 1.5vw;
	left: 49vw;
}

.splitDetails16 {
	top: 16vw;
	left: 76vw;
}

.splitDetails17 {
	top: 15vw;
	left: 78vw;
}

.splitDetails18 {
	top: 16vw;
	left: 79vw;
}

.splitDetails19 {
	top: 23vw;
	left: 82vw;
}

.splitDetails20 {
	top: 20vw;
	left: 85vw;
}

.splitDetails21 {
	top: 18vw;
	left: 83vw;
}

.splitDetails22 {
	top: 32vw;
	left: 79vw;
}

.splitDetails23 {
	top: 36vw;
	left: 82vw;
}

.splitDetails24 {
	top: 39vw;
	left: 82vw;
}

.splitDetails25 {
	top: 39vw;
	left: 86vw;
}

.splitDetails26 {
	top: 34vw;
	left: 87vw;
}

.splitDetails27 {
	top: 41vw;
	left: 86vw;
}

.splitDetails28 {
	top: 44vw;
	left: 89vw;
}

.splitDetails29 {
	top: 37vw;
	left: 92vw;
}

.splitDetails30 {
	top: 42vw;
	left: 94vw;
}

.splitDetails31 {
	top: 33vw;
	left: 92vw;
}

.splitDetails32 {
	top: 43vw;
	left: 76vw;
}

.splitDetails33 {
	top: 49vw;
	left: 81vw;
}

.splitDetails34 {
	top: 47vw;
	left: 86vw;
	writing-mode: horizontal-tb;
}

.splitDetails35 {
	top: 48vw;
	left: 89vw;
	writing-mode: horizontal-tb;
}

.splitDetails36 {
	top: 51.8vw;
	left: 90vw;
	writing-mode: horizontal-tb;
}

.splitDetails37 {
	top: 2vw;
	left: 0.5vw;
	font-style: italic;
}

/* --------- SongTree ---------- */

.treeDetails1 {
	top: 36vw;
	left: 7vw;
}

.treeDetails2 {
	top: 32vw;
	left: 8vw;
}

.treeDetails3 {
	top: 42vw;
	left: 12vw;
}

.treeDetails4 {
	top: 4vw;
	left: 30vw;
}

.treeDetails5 {
	top: 7vw;
	left: 32vw;
}

.treeDetails6 {
	top: 13vw;
	left: 35vw;
}

.treeDetails7 {
	top: 16vw;
	left: 33vw;
}

.treeDetails8 {
	top: 18vw;
	left: 39vw;
}

.treeDetails9 {
	top: 22vw;
	left: 35vw;
}

.treeDetails10 {
	top: 46vw;
	left: 65vw;
}

.treeDetails11 {
	top: 43vw;
	left: 64vw;
}

.treeDetails12 {
	top: 46vw;
	left: 70vw;
}

.treeDetails13 {
	top: 22vw;
	left: 70vw;
}

.treeDetails14 {
	top: 30vw;
	left: 72vw;
}

.treeDetails15 {
	top: 29vw;
	left: 72vw;
}

.treeDetails16 {
	top: 32vw;
	left: 75vw;
}

.treeDetails17 {
	top: 2vw;
	right: 1vw;
}

/* --------- SongArrival ---------- */

.arrivalDetails1 {
	top: 2vw;
	left: 4vw;
}

.arrivalDetails2 {
	top: 4vw;
	left: 4vw;
}

.arrivalDetails3 {
	top: 3vw;
	left: 10vw;
}

.arrivalDetails4 {
	top: 1vw;
	left: 11vw;
}

.arrivalDetails5 {
	top: 4vw;
	left: 16vw;
}

.arrivalDetails6 {
	top: 8vw;
	left: 16vw;
}

.arrivalDetails7 {
	top: 27vw;
	left: 37vw;
}

.arrivalDetails8 {
	top: 31vw;
	left: 41vw;
}

.arrivalDetails9 {
	top: 33vw;
	left: 41vw;
}

.arrivalDetails10 {
	top: 6vw;
	left: 41vw;
}

.arrivalDetails11 {
	top: 8vw;
	left: 45vw;
}

.arrivalDetails12 {
	top: 10vw;
	left: 48vw;
}

.arrivalDetails13 {
	top: 13vw;
	left: 46vw;
}

.arrivalDetails14 {
	top: 11vw;
	left: 52vw;
}

.arrivalDetails15 {
	top: 44vw;
	left: 55vw;
}

.arrivalDetails16 {
	top: 40vw;
	left: 55vw;
}

.arrivalDetails17 {
	top: 20vw;
	left: 75vw;
}

.arrivalDetails18 {
	top: 20vw;
	left: 80vw;
}

.arrivalDetails19 {
	top: 24vw;
	left: 79vw;
}

.arrivalDetails20 {
	top: 26vw;
	left: 85vw;
}

.arrivalDetails21 {
	top: 2vw;
	right: 0.5vw;
	font-style: italic;
}

/* ---------- SongNewWorld ---------- */

.newWorldDetails1 {
	top: 8vw;
	left: 8vw;
}

.newWorldDetails2 {
	top: 18vw;
	left: 11vw;
}

.newWorldDetails3 {
	top: 13vw;
	left: 11vw;
}

.newWorldDetails4 {
	top: 16vw;
	left: 15vw;
}

.newWorldDetails5 {
	top: 11vw;
	left: 14vw;
}

.newWorldDetails6 {
	top: 31vw;
	left: 20vw;
}

.newWorldDetails7 {
	top: 29vw;
	left: 21vw;
}

.newWorldDetails8 {
	top: 37vw;
	left: 25vw;
}

.newWorldDetails9 {
	top: 45vw;
	left: 27vw;
}

.newWorldDetails10 {
	top: 39vw;
	left: 27vw;
}

.newWorldDetails11 {
	top: 3vw;
	left: 39vw;
}

.newWorldDetails12 {
	top: 2vw;
	left: 43vw;
}

.newWorldDetails13 {
	top: 8vw;
	left: 45vw;
}

.newWorldDetails14 {
	top: 6vw;
	left: 47vw;
}

.newWorldDetails15 {
	top: 3vw;
	left: 47vw;
}

.newWorldDetails16 {
	top: 40vw;
	left: 45vw;
}

.newWorldDetails17 {
	top: 40vw;
	left: 49vw;
}

.newWorldDetails18 {
	top: 45vw;
	left: 50vw;
}

.newWorldDetails19 {
	top: 42vw;
	left: 50vw;
}

.newWorldDetails20 {
	top: 20vw;
	left: 59vw;
}

.newWorldDetails21 {
	top: 26vw;
	left: 58vw;
}

.newWorldDetails22 {
	top: 27vw;
	left: 63vw;
}

.newWorldDetails23 {
	top: 22vw;
	left: 61vw;
}

.newWorldDetails24 {
	top: 8vw;
	left: 82vw;
}

.newWorldDetails25 {
	top: 8vw;
	left: 87vw;
}

.newWorldDetails26 {
	top: 12vw;
	left: 85vw;
}

.newWorldDetails27 {
	top: 20vw;
	left: 89vw;
}

.newWorldDetails28 {
	top: 42vw;
	right: 0.5vw;
	font-style: italic;
}